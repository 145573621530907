.root {
  @apply fixed inset-0 z-50 flex items-start justify-center bg-gray-800 bg-opacity-50 pt-16;
}

.content {
  @apply relative m-4 w-96 rounded-lg bg-white p-6 shadow-lg;
}

.header {
  @apply flex shrink-0 items-center;
}

.title {
  @apply flex-1 text-lg font-bold;
}

.body {
  @apply flex flex-1 flex-col overflow-hidden;
}

.divider {
  @apply mb-4 flex items-center justify-between border-b-2 border-dotted pb-4;
}
