.root {
  @apply animate-pulse rounded-md;
}

.light {
  @apply bg-gray-200;
}

.dark {
  @apply bg-gray-300;
}
